import React, { Component } from 'react';
import { Card, Button, message } from 'antd';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import Configuration from '../Configuration';
import Logo from '../images/dcea_logo.png';
import './Login.css';

class Login extends Component {
  constructor() {
    super();

    this.state = {username: '', password: '', redirect: false, redirectUrl: '/'};
    this.handleUser = this.handleUser.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleUser(event) {
    this.setState({username: event.target.value});
  }

  handlePassword(event) {
    this.setState({password: event.target.value});
  }

  handleCompany(event) {
    this.setState({subdomain: event.target.value});
  }

  handleLogin() {
    if(this.validate(this.state)) {
      const query = `mutation{login(login:{username: "${this.state.username}", password: "${this.state.password}"}){id,rol,name,token, globalReader}}`;

      axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}).then(resp => {        
        if(resp.status !== 200) {
          return message.warning('Hubo un error al procesar tus credenciales');
        }
        
        if('errors' in resp.data) {
          return message.warning('Usuario o contraseña invalida');
        }

        // console.log(resp.data.data);
        const response = resp.data.data.login;

        // Save localstorage
        localStorage.setItem('token', response.token);
        localStorage.setItem('name', response.name ? response.name : "Usuario");
        localStorage.setItem('userid', response.id);
        localStorage.setItem('rol', response.rol);

        // Redirect by rol
        console.log(response.rol);
        console.log(response.globalReader);
        const redirectTo = Configuration.redirectUrl(response.globalReader);
        this.setState({redirectUrl: redirectTo, redirect: true});
      });
    } else {
      message.warning('Todos los campos son obligatorios');
    }
  }

  validate(fields) {
    let pass = true;

    for(let field in fields) {
      if(field !== undefined && fields[field].length < 1) {
        pass = false;
      }
    }

    return pass;
  }

  render() {
    // Login redirect
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    // Render component
    return(
      <div style={{ background: '#f1f1f1', height: '100vh' }}>
        <div className="container-center" style={{ height: '90vh' }}>
          
          <Card className="login-content shadow border-radius-md" style={{borderTop: '2px solid #1C91FF'}}>
            <div className="container-center" style={{ paddingBottom: '10px', flexWrap: 'wrap', flexDirection: 'column', borderBottom: '1px dotted #ddd'}}>
              <img src={Logo} height="80" alt="DCEA" style={{ margin: '30px 0px 0px 0px' }} />
              <h3 style={{textAling: 'center', color: '#1C92FF', fontSize: '1.4em'}}>Sistema de Evaluación y Acreditación</h3>
            </div>
            
            <div className='container-center container-column' style={{ margin: '50px 0px' }}>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Usuario" onChange={this.handleUser} />
              </div>
              
              <div className="form-group">
                <input type="password" className="form-control" placeholder="******" onChange={this.handlePassword} />
              </div>
            </div>
            
            <div className="container-center container-column" style={{ margin: '20px 0px' }}>
              <Button type="primary" onClick={this.handleLogin}>Acceder</Button>
              <Button type="dashed" style={{ marginTop: '10px' }}><a href="/recuperar">Olvide mi contraseña</a></Button>
            </div>
          </Card>

        </div>
      </div>
    );
  }
}

export default Login;
