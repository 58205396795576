import { PlusOutlined, EditOutlined, DeleteOutlined, ExceptionOutlined, BellOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Modal, Form, Select, List, Skeleton, Slider, Tooltip } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/dcea_logo.png';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';

const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();
const { Meta } = Card;
const { Option } = Select;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class UsuarioIndicadores extends Component {
  constructor(props) {
    super(props);

    const records = [
      {key: 'ind-1', id: 1, folio: '1.6.3', name: '¿En promedio la dedicación anual a la extensión académica (congresos, foros, conferencias, entre otros), en el caso del total de los profesores de tiempo completo es igual o mayor al 25% de la descarga académica?', status: 'Pendiente'},
      {key: 'ind-2', id: 2, folio: '1.7.1', name: '¿Se aplica un reglamento de evaluación del desempeño del personal académico o equivalente?', status: 'Pendiente'},
      {key: 'ind-3', id: 3, folio: '1.7.2', name: '¿Se tiene en cuenta la calidad de la actividad docente?', status: 'Pendiente'},
    ];

    const allRecords = [
      {key: 'ind-1', id: 1, folio: '1.6.3', name: '¿En promedio la dedicación anual a la extensión académica (congresos, foros, conferencias, entre otros), en el caso del total de los profesores de tiempo completo es igual o mayor al 25% de la descarga académica?', status: 'Pendiente'},
      {key: 'ind-2', id: 2, folio: '1.7.1', name: '¿Se aplica un reglamento de evaluación del desempeño del personal académico o equivalente?', status: 'Pendiente'},
      {key: 'ind-3', id: 3, folio: '1.7.2', name: '¿Se tiene en cuenta la calidad de la actividad docente?', status: 'Pendiente'},
      {key: 'ind-4', id: 4, folio: '1.7.3', name: '¿Se toma en consideración la calidad de las actividades de investigación?', status: 'Pendiente'},
      {key: 'ind-5', id: 5, folio: '1.7.4', name: '¿Se toma en cuenta la elaboración de material didáctico, libros y las publicaciones en revistas especializadas o con arbitraje?', status: 'Pendiente'},
      {key: 'ind-6', id: 6, folio: '1.7.5', name: '¿Se tiene en cuenta la actividad laboral profesional?', status: 'En revisión'},
      {key: 'ind-7', id: 7, folio: '1.7.6', name: '¿Dentro de los requisitos de evaluación al desempeño del personal académico, también se consideran las tutorías y la gestión académica?', status: 'Con errores'},
      {key: 'ind-8', id: 8, folio: '1.7.7', name: '¿Se difunde el reglamento para la evaluación entre la comunidad académica?', status: 'Pendiente'},
      {key: 'ind-9', id: 9, folio: '1.8.1', name: '¿Se aplica un reglamento para la promoción docente o equivalente que tome en consideración la actividad docente, de investigación, publicaciones, material didáctico y la actividad laboral profesional?', status: 'Autorizado'},
      {key: 'ind-10', id: 10, folio: '1.8.2', name: '¿Se difunde el reglamento para la promoción entre la comunidad académica?', status: 'Pendiente'},
    ];

    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: allRecords, records: records, targets: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}, rol: '', itemsSideMenu: []};
  }

  componentDidMount() {
    //const params = this.props.params;
    //console.log(params);

    const rol =  localStorage.getItem('rol');
    if (rol) {
      let menu = [];
      if (rol == 'admin') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenu()});  
      }
      if (rol == 'coordinador') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenuCoor()});  
      }
      if (rol == 'profesor') {
        this.setState({rol: rol, itemsSideMenu: Configuration.sideMenuProf()});  
      }
    }
  }

  onSearch(words) {
    this.setState({searching: true});
    // Search record
    const list = this.state.allRecords.filter((el) => {
      if (el.name.match(words)) {
        return el;
      }
    });
    
    // Update list 
    this.setState({records: list, searching: false});
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    const route = Configuration.getRoute(item);
    this.setState({redirect: true, redirectUrl: route});
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo indicador'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = record.length + 1;
    record.key = `ind-${record.length + 1}`;
    record.status = 'Pendiente';
    newRecords.push(record);
    // Close modal
    setTimeout(() => {
      this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
    }, 1000);
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar indicador', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // console.log(record);
    // Find record
    const list = newRecords.map(el => { 
      if (el.id == selected.id) {
        let newRecord = {...record};
        newRecord.id = el.id;
        newRecord.key = el.key;
        newRecord.status = selected.status;
        // newRecord.maxVal = selected.maxVal;
        // newRecord.notes = selected.notes;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    setTimeout(() => {
      this.setState({openModal: false, modalTitle: '', loading: false, records: list});
    }, 1000);
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    const newRecords = this.state.records;
    newRecords.splice(filter, 1);
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  filterOption(inputValue, option) { 
    return option.name.indexOf(inputValue) > -1;
  }

  handleChange(newTargetKeys) {
    this.setState({targets: newTargetKeys});
  }

  goToTab(key) {
    const {id} = this.props.params;
    
    switch(key) {
      case 'ar-indicators':
        return null;
        case 'ar-users':
        return this.setState({redirect: true, redirectUrl: `/area/${id}`});
    }
  }

  statusColor(status) {
    switch(status) {
      case 'Pendiente':
        return '#C7AD08';
      case 'Por revisar':
        return '#C7AD08';;
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#F5212D';
      case 'Autorizado':
        return '#52C51A';
    }
  }

  assignIndicator(indicator) {
    let newRecords = this.state.records;
    indicator.id = newRecords.length + 1;
    newRecords.push(indicator);
    this.setState({openModal: false, records: newRecords});
  }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout hasSider>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={'Asignar indicador'}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false} 
          style={{width: '70%'}}
        >
          <List
            itemLayout="horizontal"
            dataSource={this.state.allRecords}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.folio}
                  description={item.name}
                />
                <Button type='primary' onClick={this.assignIndicator.bind(this, item)}>Asignar</Button>
              </List.Item>
            )}
          />
        </Modal>

        {/* Main layout and side menu */}
        <Sider style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, background: '#fff'}}>
          <div className="container-center" style={{ paddingBottom: '10px', flexWrap: 'wrap', flexDirection: 'column'}}>
            <img src={Logo} height="44" alt="DCEA" style={{ margin: '20px 0px 0px 0px' }} />
            <h3 style={{textAling: 'center', color: '#233C60', fontSize: '0.6em'}}>Sistema de Evaluación y Acreditación</h3>
          </div>

          <Menu theme="light" mode="inline" defaultSelectedKeys={['2']} items={this.state.itemsSideMenu} style={{marginTop: 20}} onClick={this.onClickSideMenu.bind(this)} />
        </Sider>

        <Layout className="site-layout" style={{marginLeft: 200}}>
          {/* Top menu */}
          <TopMenu />

          <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>
              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px', paddingTop: 10 }}>
                <h3>Indicadores de usuario</h3>

                {this.state.rol == 'admin' ? 
                  <div className='container-end' style={{width: '50%'}}>
                    <Button type="primary" icon={<PlusOutlined />} size="large" onClick={this.handleCreateModal.bind(this)}>Asignar indicador</Button>
                  </div>
                  : null}
              </div>

              {/* Content */}
              <div className="container-around">
                <List
                  className="demo-loadmore-list"
                  loading={false}
                  itemLayout="horizontal"
                  loadMore={null}
                  dataSource={this.state.records} 
                  style={{width: '100%', background: '#fff', padding: 10, textAlign: 'left'}}
                  renderItem={(item) => (
                    <List.Item>
                      <Skeleton avatar title={false} loading={false} active>
                        <List.Item.Meta
                          avatar={<ExceptionOutlined />}
                          title={<a href={`/indicador/${item.id}`} style={{paddingRight: 30}}><b>{item.folio}</b> {item.name}</a>}
                        />
                        
                        <div style={{borderWidth: 1, backgroundColor: this.statusColor(item.status), color: '#fff', padding: '5px 10px', borderRadius: 5, marginRight: 10, fontSize: 10}}>{item.status}</div>

                        <div>                          
                          <Tooltip placement="top" title={'Detalles'}>
                            <Button shape="circle" type="primary" ghost style={{marginRight: 5}} href={`/indicador/${item.id}`}><FileSearchOutlined /></Button>
                          </Tooltip>
                          
                          {this.state.rol == 'admin' ? 
                            <Tooltip placement="top" title={'Desasignar'}>
                              <Button shape="circle" danger onClick={() => { this.deleteRecord(item) }}><DeleteOutlined /></Button>
                            </Tooltip>
                          : null}
                        </div>
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(UsuarioIndicadores);
