import { PlusOutlined, SearchOutlined, DeleteOutlined, SecurityScanOutlined, EditOutlined } from '@ant-design/icons';
import { Layout, Card, Input, Button, Modal, Form, Popconfirm, Avatar, Select, Tag, message } from 'antd';
import React, { Component } from 'react';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenu from '../Components/SideMenu';
import axios from 'axios';

const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();
const { Search } = Input;
const { Meta } = Card;
const { Option } = Select;

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class Usuarios extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: [], records: [], targets: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}, openModalAssign: false, areas: [], editModal: false, passwordModal: false, rols: []};
  }

  componentDidMount() {
    //const params = this.props.params;
    //console.log(params);
    this.onSearch('');
    this.getAreas('');
    this.getRols();
  }

  onSearch(words) {
    this.setState({searching: true});

    const query = `{listAllUsers(search: "${words}"){id,name,username,email,avatar,status,rol,indicators{id},area{id,name}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al obtener los registros');
      }

      const response = resp.data.data.listAllUsers;
      // console.log(response);
      this.setState({records: response, searching: false});
    });
  }

  getAreas(words) {
    const query = `{listAreas(search: "${words}"){id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al obtener los registros');
      }

      const response = resp.data.data.listAreas;
      this.setState({areas: response});
    });
  }

  getRols() {
    const query = `{listRols{id,name}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al obtener los registros');
      }

      const response = resp.data.data.listRols;
      this.setState({rols: response});
    });
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    const route = Configuration.getRoute(item);
    this.setState({redirect: true, redirectUrl: route});
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo indicador'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('username' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    this.setState({loading: true});
    let query = `mutation{createUser(name: "${record.name}", username: "${record.username}", password: "${record.password}", rol: "${record.rol}"){id}}`;
    if (record.area) {
      query = `mutation{createUser(name: "${record.name}", username: "${record.username}", password: "${record.password}", rol: "${record.rol}", area: "${record.area}"){id}}`;
    }

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        this.setState({loading: false});
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        this.setState({loading: false});
        return message.warning('Error al crear el registro');
      }

      const response = resp.data.data.createUser;
      //console.log(response);
      this.setState({openModal: false, modalTitle: '', loading: false});
      this.onSearch('');
    });
  }

  handleEditModal(record) {
    const newRecord = {...record};
    this.setState({editModal: true, modalTitle: 'Editar usuario', selectedRecord: newRecord});
  }

  updateRecord(record) {
    const selected = this.state.selectedRecord;
    this.setState({loading: true});
    let query = `mutation{updateUser(id: ${selected.id}, name: "${selected.name}", username: "${selected.username}", rol: "${selected.rol}"){id}}`;
    if (record.area) {
      query = `mutation{updateUser(id: ${selected.id}, name: "${selected.name}", username: "${selected.username}", rol: "${selected.rol}", area: "${selected.area?.id}"){id}}`;
    }

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        this.setState({loading: false});
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        this.setState({loading: false});
        return message.warning('Error al crear el registro');
      }

      const response = resp.data.data.updateUser;
      //console.log(response);
      this.setState({editModal: false, modalTitle: '', loading: false, selectedRecord: {}});
      this.onSearch('');
    });
  }

  deleteRecord(record) {
    this.setState({loading: true});
    const query = `mutation{deleteUser(id: ${record.id}){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        this.setState({loading: false});
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        this.setState({loading: false});
        return message.warning('Error al borrar el registro');
      }

      const response = resp.data.data.deleteUser;
      //console.log(response);
      this.setState({loading: false, selectedRecord: {}});
      this.onSearch('');
    });
  }

  handleSubmitPasswordModal(record) {
    const selected = this.state.selectedRecord;
    this.setState({loading: true});
    const query = `mutation{passwordUser(id: ${selected.id}, password: "${record.password}"){id}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        this.setState({loading: false});
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        this.setState({loading: false});
        return message.warning('Error al crear el registro');
      }

      const response = resp.data.data.passwordUser;
      //console.log(response);
      this.setState({passwordModal: false, modalTitle: '', loading: false, selectedRecord: {}});
      this.onSearch('');
    });
  }

  handleCancelModal() {
    this.setState({openModal: false, editModal: false, modalTitle: '', selectedRecord: {}});
  }

  handlePasswordModal(record) {
    this.setState({passwordModal: true, modalTitle: 'Contraseña usuario', selectedRecord: record});
  }

  handleCancelPasswordModal() {
    this.setState({passwordModal: false, modalTitle: ''});
  }

  filterOption(inputValue, option) { 
    return option.name.indexOf(inputValue) > -1;
  }

  handleChange(newTargetKeys) {
    this.setState({targets: newTargetKeys});
  }

  goToTab(key) {
    const {id} = this.props.params;

    switch(key) {
      case 'ar-indicators':
        return this.setState({redirect: true, redirectUrl: `/area-indicadores/${id}`});
        case 'ar-users':
        return null;
    }
  }

  handleModalAssign() {
    this.setState({openModalAssign: true});
  }

  handleCancelModalAssign() {
    this.setState({openModalAssign: false}); 
  }

  assignUser(user) {
    // console.log(user);
    let newRecords = this.state.records;
    user.id = newRecords.length + 1;
    newRecords.push(user);
    this.setState({openModalAssign: false, records: newRecords});
  }


  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout style={{background: '#fff'}}>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitModal.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            <Form.Item label="Nombre completo" name="name" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Usuario" name="username" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Contraseña" name="password" rules={[{required: false, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Área" name="area" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Select
                showSearch
                placeholder="Elige un área"
                optionFilterProp="children"
                onChange={() => {}}
                onSearch={() => {}}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.areas.map((item) => ({value: item.id, label: item.name}))}
              />
            </Form.Item>

            <Form.Item label="Rol" name="rol" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Select
                showSearch
                placeholder="Elige un rol"
                optionFilterProp="children"
                onChange={() => {}}
                onSearch={() => {}}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.rols.map((item) => ({value: item.id, label: item.name}))}
              />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Update */}
        <Modal
          open={this.state.editModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitModal.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            {/* <Form.Item label="Nombre completo" name="name" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item> */}
            <div style={{marginBottom: 15}}>
              <label htmlFor="">Nombre completo <span style={{color: "#ff4d4f"}}>*</span></label>
              <input type='text' className='ant-input' value={this.state.selectedRecord.name} onChange={(e) => { this.setState({selectedRecord: {...this.state.selectedRecord, name: e.target.value}}) }} />
            </div>

            {/* <Form.Item label="Usuario" name="username" rules={[{required: true, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item> */}
            <div style={{marginBottom: 15}}>
              <label htmlFor="">Usuario <span style={{color: "#ff4d4f"}}>*</span></label>
              <input type='text' className='ant-input' value={this.state.selectedRecord.username} onChange={(e) => { this.setState({selectedRecord: {...this.state.selectedRecord, username: e.target.value}}) }} />
            </div>

            {/* <Form.Item label="Área" name="area" rules={[{required: true, message: 'Debes llenar este campo.'}]}> */}
            <div style={{marginBottom: 15}}>
              <label htmlFor="">Área <span style={{color: "#ff4d4f"}}>*</span> {this.state.selectedRecord?.area?.id}</label>
              <Select
                placeholder="Elige un área"
                optionFilterProp="children"
                onChange={(e) => { this.setState({selectedRecord: {...this.state.selectedRecord, area: e.target.value}}) }}
                value={this.state.selectedRecord?.area?.id}
                options={this.state.areas.map((item) => ({value: item.id, label: item.name}))}
                style={{width: "100%"}}
              />
            </div>
            {/* </Form.Item> */}

            {/* <Form.Item label="Rol" name="rol" rules={[{required: true, message: 'Debes llenar este campo.'}]}> */}
            <div style={{marginBottom: 15}}>
              <label htmlFor="">Rol <span style={{color: "#ff4d4f"}}>*</span></label>
              <Select
                placeholder="Elige un rol"
                optionFilterProp="children"
                onChange={(e) => { this.setState({selectedRecord: {...this.state.selectedRecord, rol: e}}) }}
                value={this.state.selectedRecord?.rol}
                options={this.state.rols.map((item) => ({value: item.name, label: item.name}))}
                style={{width: "100%"}}
              />
            </div>
            {/* </Form.Item> */}

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>
        {/* Password */}
        <Modal
          open={this.state.passwordModal}
          title={this.state.modalTitle}
          onOk={this.handleSubmitPasswordModal.bind(this)}
          onCancel={this.handleCancelPasswordModal.bind(this)}
          footer={false}
        >
          <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={this.state.selectedRecord} onFinish={this.handleSubmitPasswordModal.bind(this)} onFinishFailed={() => {}} autoComplete="off">
            <Form.Item label="Contraseña" name="password" rules={[{required: false, message: 'Debes llenar este campo.'}]}>
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button onClick={this.handleCancelPasswordModal.bind(this)} style={{marginRight: 5}}>Cancelar</Button>
              <Button type="primary" htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Top menu */}
        <TopMenu />

        {/* Content with two columns */}
        <Layout className="" style={{background: '#fff'}}>
          <SideMenu />

          <Content style={{margin: '24px 16px 0'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>
              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px' }}>
                <h3>Usuarios</h3>

                <div className='container-end' style={{width: '50%'}}>
                  <Search placeholder="Busca por nombre..." onSearch={this.onSearch.bind(this)} style={{ width: '70%' }} enterButton="Buscar" size="large" loading={this.state.searching} />
                  <Button type="primary" icon={<PlusOutlined />} size="large" onClick={this.handleCreateModal.bind(this)}>Crear usuario</Button>
                </div>
              </div>

              {/* Content */}
              <div className="container-start" style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                {this.state.records.map((item) => {
                  return (
                    <Card
                      style={{width: '32%', marginLeft: 5, marginRight: 5, marginTop: 10}}
                      actions={[
                        <a href={`/usuario-indicadores/${item.id}`}><SearchOutlined key="setting" /></a>, 
                        <EditOutlined key="edit" title='Editar' onClick={this.handleEditModal.bind(this, item)} />,
                        <SecurityScanOutlined key="password" title='Contraseña' onClick={this.handlePasswordModal.bind(this, item)} />,
                        <Popconfirm title="¿Estás seguro" cancelText='Cancelar' onConfirm={this.deleteRecord.bind(this, item)}>
                          <DeleteOutlined key="delete" title='Borrar' />
                        </Popconfirm>
                      ]}
                      key={`user-${item.id}`}
                    >
                      <Meta
                        avatar={<Avatar src={item.avatar} />}
                        title={item.name}
                      />

                      <div style={{paddingTop: 10, borderTop: 'dotted 1px #ddd', marginTop: 25}}>
                        {item.area ? 
                          <p style={{textAlign: 'center', marginBottom: 5, color: '#777'}}>Área: <b>{item.area?.name}</b></p>
                        : null}
                        <p style={{textAlign: 'center', marginTop: 0, marginBottom: 5, color: '#777'}}>Usuario: <b>{item.username}</b></p>
                        <p style={{textAlign: 'center', marginTop: 0, marginBottom: 5, color: '#777'}}>Rol: <b>{item.rol}</b></p>
                        <p style={{textAlign: 'center', marginTop: 0, color: '#777'}}><Tag color='blue'>{item.indicators?.length}</Tag> Indicadores</p>
                      </div>
                    </Card>
                  )
                })}
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(Usuarios);
