import { LikeOutlined, BellOutlined, CheckCircleOutlined, DeleteOutlined, EditOutlined, ExceptionOutlined, FileSearchOutlined} from '@ant-design/icons';
import { Layout, Menu, Card, Input, Button, Progress, DatePicker, List, Skeleton, Select } from 'antd';
import React, { Component } from 'react';
import Logo from '../images/dcea_logo.png';
import Configuration from '../Configuration.js';
import { Navigate } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';

const { RangePicker } = DatePicker;
const { Meta } = Card;
const { Search } = Input;
const { Header, Content, Sider } = Layout;
const items = Configuration.sideMenu();

class Reportes extends Component {
  constructor(props) {
    super(props);

    const records = [
      {key: 'ind-1', id: 1, folio: '1.6.3', name: '¿En promedio la dedicación anual a la extensión académica (congresos, foros, conferencias, entre otros), en el caso del total de los profesores de tiempo completo es igual o mayor al 25% de la descarga académica?', status: 'Pendiente', area: 'Ciencias'},
      {key: 'ind-2', id: 2, folio: '1.7.1', name: '¿Se aplica un reglamento de evaluación del desempeño del personal académico o equivalente?', status: 'Pendiente', area: 'Ciencias'},
      {key: 'ind-3', id: 3, folio: '1.7.2', name: '¿Se tiene en cuenta la calidad de la actividad docente?', status: 'Pendiente', area: 'Ciencias'},
      {key: 'ind-4', id: 4, folio: '1.7.3', name: '¿Se toma en consideración la calidad de las actividades de investigación?', status: 'Pendiente', area: 'Ciencias'},
      {key: 'ind-5', id: 5, folio: '1.7.4', name: '¿Se toma en cuenta la elaboración de material didáctico, libros y las publicaciones en revistas especializadas o con arbitraje?', status: 'Pendiente', area: 'Ciencias'},
      {key: 'ind-6', id: 6, folio: '1.7.5', name: '¿Se tiene en cuenta la actividad laboral profesional?', status: 'En revisión', area: 'Ciencias'},
      {key: 'ind-7', id: 7, folio: '1.7.6', name: '¿Dentro de los requisitos de evaluación al desempeño del personal académico, también se consideran las tutorías y la gestión académica?', status: 'Con errores', area: 'Ciencias'},
      {key: 'ind-8', id: 8, folio: '1.7.7', name: '¿Se difunde el reglamento para la evaluación entre la comunidad académica?', status: 'Pendiente', area: 'Finanzas'},
      {key: 'ind-9', id: 9, folio: '1.8.1', name: '¿Se aplica un reglamento para la promoción docente o equivalente que tome en consideración la actividad docente, de investigación, publicaciones, material didáctico y la actividad laboral profesional?', status: 'Autorizado', area: 'Ciencias'},
      {key: 'ind-10', id: 10, folio: '1.8.2', name: '¿Se difunde el reglamento para la promoción entre la comunidad académica?', status: 'Pendiente', area: 'Finanzas'},
    ];
    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: records, records: records, loading: false, openModal: false, modalTitle: '', selectedRecord: {}};
  }

  onSearch(words) {
    this.setState({searching: true});

    // Search record
    const list = this.state.allRecords.filter((el) => {
      if (el.title.match(words)) {
        return el;
      }
    });
    
    // Update list 
    this.setState({records: list, searching: false});
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    if (item.key != "2") {
      const route = Configuration.getRoute(item);
      this.setState({redirect: true, redirectUrl: route});
    }
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo área'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = newRecords.length + 1;
    record.users = 0;
    record.indicators = 0;
    newRecords.push(record);
    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar área', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // Find record
    const list = newRecords.map(el => { 
      if (el.id == selected.id) {
        let newRecord = {...el};
        newRecord.name = record.name;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    this.setState({openModal: false, modalTitle: '', loading: false, records: list});
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    const newRecords = this.state.records;
    delete newRecords[filter];
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  openDetails(record) {
    this.setState({redirect: true, redirectUrl: `/area/${record.id}`});
  }

  statusColor(status) {
    switch(status) {
      case 'Pendiente':
        return '#C7AD08';
      case 'Por revisar':
        return '#C7AD08';;
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#F5212D';
      case 'Autorizado':
        return '#52C51A';
    }
  }



  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout hasSider>
        {/* Main layout and side menu */}
        <Sider style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, background: '#fff'}}>
          <div className="container-center" style={{ paddingBottom: '10px', flexWrap: 'wrap', flexDirection: 'column'}}>
            <img src={Logo} height="44" alt="DCEA" style={{ margin: '20px 0px 0px 0px' }} />
            <h3 style={{textAling: 'center', color: '#233C60', fontSize: '0.6em'}}>Sistema de Evaluación y Acreditación</h3>
          </div>

          <Menu theme="light" mode="inline" defaultSelectedKeys={['4']} items={items} style={{marginTop: 20}} onClick={this.onClickSideMenu.bind(this)} />
        </Sider>

        <Layout className="site-layout" style={{marginLeft: 200}}>
          {/* Top menu */}
          <TopMenu />

          <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>

              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '20px' }}>
                <h3>Reporte de indicadores</h3>
              </div>

              {/* Content */}
              <div className="container-around" style={{borderBottom: '1px dotted #ddd', paddingBottom: 30, marginBottom: 30}}>
                <div className="container-between" style={{border: '1px solid #ddd', borderRadius: 15, padding: 10, width: '60%', marginBottom: 5}}>
                  <h4 style={{marginBottom: 0, width: 150}}>Pendientes</h4>
                  <Progress strokeLinecap="butt" percent={50} />
                </div>
                <div className="container-between" style={{border: '1px solid #ddd', borderRadius: 15, padding: 10, width: '60%', marginBottom: 5}}>
                  <h4 style={{marginBottom: 0, width: 150}}>Errores</h4>
                  <Progress strokeLinecap="butt" percent={20} />
                </div>
                <div className="container-between" style={{border: '1px solid #ddd', borderRadius: 15, padding: 10, width: '60%', marginBottom: 5}}>
                  <h4 style={{marginBottom: 0, width: 150}}>Autorizados</h4>
                  <Progress strokeLinecap="butt" percent={30} />
                </div>
              </div>

              <div className="container-between" style={{border: '1px solid #ddd', borderRadius: 15, padding: 20, width: '100%', marginBottom: 5}}>
                <RangePicker style={{ width: '30%' }} />

                <Select
                  defaultValue="Todas"
                  style={{ width: '30%' }}
                  onChange={() => {}}
                  options={[
                    {value: 'Todas', label: 'Todas'},
                    {value: 'Ciencias', label: 'Ciencias'},
                    {value: 'Finanzas', label: 'Finanzas'},
                  ]}
                />
                
                <div>
                  <Button type="primary" style={{marginRight: 10}}><a href="/limpiar">Filtrar</a></Button>
                  <Button><a href="/limpiar">Exportar</a></Button>
                </div>
              </div>

              <List
                className="demo-loadmore-list"
                loading={false}
                itemLayout="horizontal"
                loadMore={null}
                dataSource={this.state.records} 
                style={{width: '100%', background: '#fff', padding: 10, textAlign: 'left'}}
                renderItem={(item) => (
                  <List.Item>
                    <Skeleton avatar title={false} loading={false} active>
                      <List.Item.Meta
                        avatar={<ExceptionOutlined />}
                        title={<a href={`/indicador/${item.id}`} style={{paddingRight: 30}}><b>{item.folio}</b> {item.name}</a>}
                      />
                      
                      <div style={{borderWidth: 1, backgroundColor: '#212121', color: '#fff', padding: '5px 10px', borderRadius: 5, marginRight: 10, fontSize: 10}}>{item.area}</div>
                      <div style={{borderWidth: 1, backgroundColor: this.statusColor(item.status), color: '#fff', padding: '5px 10px', borderRadius: 5, marginRight: 10, fontSize: 10}}>{item.status}</div>
                    </Skeleton>
                  </List.Item>
                )}
              />
            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default Reportes;
