import { PlusOutlined, AppstoreOutlined, DeleteOutlined, EditOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Layout, Button, Modal, Popconfirm, Select, List, Row, Col, Tooltip, message } from 'antd';
import React, { Component } from 'react';
import Configuration from '../Configuration.js';
import { Navigate, useParams } from 'react-router-dom';
import TopMenu from '../Components/TopMenu';
import SideMenuProfesor from '../Components/SideMenuProfesor';
import axios from 'axios';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const { Content, Sider } = Layout;

class MisIndicadores extends Component {
  constructor(props) {
    super(props);

    // Auth
    const jwt = localStorage.getItem('token');
    this.headers = {authorization: `Bearer: ${jwt}`};

    this.state = {searching: false, redirect: false, redirectUrl: '', allRecords: [], records: [], targets: [], loading: false, openModal: false, modalTitle: '', selectedRecord: {}, rol: '', itemsSideMenu: []};
  }

  componentDidMount() {
    //const params = this.props.params;
    //console.log(params);

    const rol =  localStorage.getItem('rol');
    this.onSearch('');
  }

  onSearch(words) {
    this.setState({searching: true});

    const query = `{listIndicatorForProfesor{id,folio,question,status,maxVal,notes,user{id,name},area{id,name},users{id,name},areas{id,name}}}`;

    axios.post(`${Configuration.apiServer}/api/v1?query=${query}`, {}, {headers: this.headers}).then(resp => {
      if(resp.status !== 200) {
        return message.warning('Hubo un error al procesar tus credenciales');
      }
      
      if('errors' in resp.data) {
        console.log(resp.data);
        return message.warning('Error al consultar los indicadores.');
      }

      this.setState({records: resp.data.data.listIndicatorForProfesor, searching: false});
    });
  }

  onClickMainMenu(item) {
    switch(item.key) {
      case '0':
        this.setState({redirect: true, redirectUrl: '/notificaciones'});
        break;
      case '1':
        this.setState({redirect: true, redirectUrl: '/login'});
        break;
    }
  }

  onClickSideMenu(item) {
    const route = Configuration.getRoute(item);
    this.setState({redirect: true, redirectUrl: route});
  }

  handleCreateModal() {
    this.setState({openModal: true, modalTitle: 'Nuevo indicador'});
  }

  handleSubmitModal(data) {
    this.setState({loading: true});

    // Update or create
    if ('name' in this.state.selectedRecord) {
      this.updateRecord(data);
    } else {
      this.createRecord(data);
    }
  }

  createRecord(record) {
    // Add new record
    const newRecords = this.state.records;
    record.id = record.length + 1;
    record.key = `ind-${record.length + 1}`;
    record.status = 'Pendiente';
    newRecords.push(record);
    // Close modal
    setTimeout(() => {
      this.setState({openModal: false, modalTitle: '', loading: false, records: newRecords});
    }, 1000);
  }

  handleEditModal(record) {
    this.setState({openModal: true, modalTitle: 'Editar indicador', selectedRecord: record});
  }

  updateRecord(record) {
    const newRecords = this.state.records;
    const selected = this.state.selectedRecord;
    // console.log(record);
    // Find record
    const list = newRecords.map(el => { 
      if (el.id == selected.id) {
        let newRecord = {...record};
        newRecord.id = el.id;
        newRecord.key = el.key;
        newRecord.status = selected.status;
        // newRecord.maxVal = selected.maxVal;
        // newRecord.notes = selected.notes;
        return newRecord;
      } else {
        return el;
      }
    });

    // Close modal
    setTimeout(() => {
      this.setState({openModal: false, modalTitle: '', loading: false, records: list});
    }, 1000);
  }

  deleteRecord(record) {
    const filter = this.state.records.findIndex((el, index) => {
      if (record.id == el.id) {
        return el;
      }
    });
    
    const newRecords = this.state.records;
    newRecords.splice(filter, 1);
    this.setState({records: newRecords});
  }

  handleCancelModal() {
    this.setState({openModal: false, modalTitle: ''});
  }

  filterOption(inputValue, option) { 
    return option.name.indexOf(inputValue) > -1;
  }

  handleChange(newTargetKeys) {
    this.setState({targets: newTargetKeys});
  }

  goToTab(key) {
    const {id} = this.props.params;
    
    switch(key) {
      case 'ar-indicators':
        return null;
        case 'ar-users':
        return this.setState({redirect: true, redirectUrl: `/area/${id}`});
    }
  }

  statusColor(status) {
    switch(status) {
      case 'Pendiente':
        return '#C7AD08';
      case 'Por revisar':
        return '#C7AD08';;
      case 'En revisión':
        return '#FA8C16';
      case 'Con errores':
        return '#F5212D';
      case 'Autorizado':
        return '#52C51A';
    }
  }

  assignIndicator(indicator) {
    let newRecords = this.state.records;
    indicator.id = newRecords.length + 1;
    newRecords.push(indicator);
    this.setState({openModal: false, records: newRecords});
  }

  // Render UI
  render() {
    if(this.state.redirect) {
      return (
        <Navigate to={this.state.redirectUrl} />
      );
    }

    return (
      <Layout style={{background: '#fff'}}>
        {/* Modal for new and edit */}
        <Modal
          open={this.state.openModal}
          title={'Asignar indicador'}
          onOk={this.handleSubmitModal.bind(this)}
          onCancel={this.handleCancelModal.bind(this)}
          footer={false} 
          style={{width: '70%'}}
        >
          <List
            itemLayout="horizontal"
            dataSource={this.state.allRecords}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.folio}
                  description={item.name}
                />
                <Button type='primary' onClick={this.assignIndicator.bind(this, item)}>Asignar</Button>
              </List.Item>
            )}
          />
        </Modal>

        {/* Top menu */}
        <TopMenu />

        <Layout className="" style={{background: '#fff'}}>
          {/* Main layout and side menu */}
          <SideMenuProfesor />

          <Content style={{margin: '24px 16px 0'}}>
            <div className="site-layout-background" style={{padding: 24, textAlign: 'center', height: '85vh', overflowY: 'scroll'}}>
              {/* Header seccions */}
              <div className='container-between' style={{ marginBottom: '30px', paddingTop: 10 }}>
                <h3>Mis indicadores</h3>

                {this.state.rol == 'admin' ? 
                  <div className='container-end' style={{width: '50%'}}>
                    <Button type="primary" icon={<PlusOutlined />} size="large" onClick={this.handleCreateModal.bind(this)}>Asignar indicador</Button>
                  </div>
                  : null}
              </div>

              {/* Content */}
              <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
              <div className="">
                  <List
                    itemLayout="horizontal"
                    dataSource={this.state.records}
                    renderItem={item => (
                      <List.Item key={`car-${item.id}`} style={{width: '100%'}}>
                          <Row style={{width: '100%'}}>
                            <Col flex="1 1 200px">
                              <List.Item.Meta
                                title={
                                  <div style={{textAlign: 'left', paddingRight: '40px'}}>
                                    <div style={{backgroundColor: '#ddd', padding: '10px 5px', borderRadius: 5}}>
                                      <span style={{marginRight: '6px', fontWeight: 'bold'}}>{item.folio}</span>
                                      <span style={{color: '#1B3C6A', paddingRight: 30}}>
                                        <b>{item.question}</b>
                                      </span>
                                    </div>

                                    <div style={{backgroundColor: '#f1f1f1', padding: '10px 5px', borderRadius: 5}}>
                                      <div style={{marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-start'}}>
                                        <span style={{marginRight: 5}}>Asignado a</span>
                                        {item.users.map((u) => {
                                          return (<div key={`ind-user-${u.id}`} style={{border: '1px solid #ccc', borderRadius: 5, marginRight: 5, padding: 5}}>
                                            <span style={{marginRight: 10}}>{u.name} </span>
                                          </div>);
                                        })}
                                      </div>

                                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-start'}}>
                                        <span style={{marginRight: 5}}>Áreas</span>
                                        {item.areas.map((u) => {
                                          return (<div key={`ind-area-${u.id}`} style={{border: '1px solid #ccc', borderRadius: 5, marginRight: 5, padding: 5}}>
                                            <span style={{marginRight: 10}}>{u.name} </span>
                                          </div>);
                                        })}
                                      </div>
                                    </div>

                                    {item.notes ?
                                      <div style={{marginTop: 10}} dangerouslySetInnerHTML={{ __html: item.notes }} />
                                    : null}
                                  </div>
                                }
                              />
                            </Col>

                            <Col span={4}>
                              {/* <div style={{padding: '5px 10px', border: '1px solid #777', borderRadius: 10, marginRight: 10, fontSize: 12, marginBottom: '5px'}}>Valor máximo: {item.maxVal}</div> */}
                              <div style={{borderWidth: 1, backgroundColor: this.statusColor(item.status), color: '#fff', padding: '5px 10px', borderRadius: 10, marginRight: 10, fontSize: 12}}>{item.status}</div>
                            </Col>

                            <Col span={4}>
                              <Tooltip placement="top" title={'Detalles'}>
                                <Button shape="circle" type="primary" ghost style={{marginRight: 5}} href={`/indicador/${item.id}`}><FileSearchOutlined /></Button>
                              </Tooltip>
                            </Col>
                          </Row>
                      </List.Item>
                    )}
                  />
                </div>
              </div>

            </div>
          </Content>


        </Layout>
      </Layout>
    );
  }
}

export default withParams(MisIndicadores);
